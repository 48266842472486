import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import SEO from '../components/seo'
import styles from './news-article.module.scss'

const NewsArticlePage = ({ pageContext: { locale, hrefLangs }, data }) => {
  const article = data.allContentfulNews.edges[0].node
  return (
    <Layout locale={locale}>
      <SEO
        title={article.title}
        description={article.title}
        type="article"
        hrefLangs={hrefLangs}
      />
      <main role="main" className={styles.main}>
        <section>
          <article className={styles.article}>
            <div className={styles.title}>
              <h1>{article.title}</h1>
              <time>{article.publishedAt}</time>
            </div>
            <div
              className={styles.content}
              dangerouslySetInnerHTML={{
                __html: article.content.childMarkdownRemark.html,
              }}
            ></div>
          </article>
        </section>
      </main>
    </Layout>
  )
}

export default NewsArticlePage

export const query = graphql`
  query($newsId: String!, $localeLanguage: String!) {
    allContentfulNews(
      filter: { node_locale: { eq: $localeLanguage }, newsId: { eq: $newsId } }
    ) {
      edges {
        node {
          newsId
          title
          publishedAt
          content {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
  }
`
